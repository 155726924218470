(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ProductCardNgController', ProductCardNgController)
        .constant('productCardNgResolver', productCardNgResolver());


    ProductCardNgController.$inject = ['$scope', '$window', '$interval', '$stateParams', '$timeout', '$q', 'spidModal', '$filter', '$state', '$document', '$translate', '$rootScope', '$location', '$analytics',
        'wediaService', 'sportService',
        'genders', 'sportsRepository', 'envService', 'parameterService', 'productService', 'brandService', 'userSettings', 'mediaService', 'previousSmSeason', 'countriesService', 'dialogService',
        'genderService', 'structurationService', 'toastService', 'userBenefits', 'materialService', 'structurations', 'modelService', 'superModelService', 'propagationService', 'versionService', 'declinaisonService',
        'languages', 'lastSearchService',
        'productCardDto', 'timeline',
        'colorsRepository', 'flavorsRepository', 'fragrancesRepository', 'careInstructionsRepository', 'careSymbolsRepository', 'warningsRepository', 'materialsRepository', 'productPartsRepository', 'modelValidationService', 'productNatureService'];

    function ProductCardNgController($scope, $window, $interval, $stateParams, $timeout, $q, spidModal, $filter, $state, $document, $translate, $rootScope, $location, $analytics,
                                     wediaService, sportService,
                                     genders, sportsRepository, envService, parameterService, productService, brandService, userSettings, mediaService, previousSmSeason, countriesService, dialogService,
                                     genderService, structurationService, toastService, userBenefits, materialService, structurations, modelService, superModelService, propagationService, versionService, declinaisonService,
                                     languages, lastSearchService,
                                     productCardDto, timeline,
                                     colorsRepository, flavorsRepository, fragrancesRepository, careInstructionsRepository, careSymbolsRepository, warningsRepository, materialsRepository, productPartsRepository, modelValidationService, productNatureService) {

        const ecoDesignUserBenefitId = 201;

        $scope.productCardDto = productCardDto;
        $scope.initLocale = $stateParams.initLocale;
        $scope.canEdit = !$rootScope.migratedLocales.some(ml => ml === productCardDto.locale) || userSettings.hasRole('ROLE_ADMIN') || !$rootScope.migratedLocales;
        $scope.hasRoleCUD = $scope.canEdit && (userSettings.hasRole('ROLE_CUD', productCardDto.locale.split('_')[1]) ||
            (productCardDto.models.some(m => m.primaryOwner === userSettings.getUser().uid) && userSettings.isFranchisees()));
        $scope.hasAuthorityCUD = userSettings.hasAuthority('ROLE_CUD');
        $scope.selectedModelCode = $stateParams.modelCode || ($scope.productCardDto.models[0] ? $scope.productCardDto.models[0].code : null);
        $scope.initConfirmationPopup = false;

        if (!$scope.canEdit) {
            $scope.localeLabUrl = envService.getLocaleLabUrl() + '?workingLocale=' + productCardDto.locale.split('_')[0] + '-' + productCardDto.locale.split('_')[1];
        }

        _($scope.productCardDto.models)
            .each(function (model) {
                mediaService.wrapModelMedias(model.medias);
            });

        buildSports($scope.productCardDto);

        function findSportById(id) {
            var sportCache = null;
            sportsRepository.forEach(function (sportGroup) {
                sportGroup.sports.forEach(function (sport) {
                    if (sport.id === id) {
                        sportCache = sport;
                        sportCache.fatherId = sportGroup.id;
                        sportCache.fatherName = sportGroup.name;
                    }
                });
            });
            return sportCache;
        }

        initInternationalConfirmationLanguagePopUp($stateParams, $rootScope, spidModal, $scope);

        $scope.saveWithPopup = function saveWithPopup() {
            const characteristicsWithNoNameAndNoDescription = $scope.model.characteristics.filter(characteristic => !characteristic.name.value && !characteristic.description.value);
            const characteristicsWithNameAndNoDescription = $scope.model.characteristics.filter(characteristic => characteristic.name.value && !characteristic.description.value);
            const characteristicsWithNoNameAndDescription = $scope.model.characteristics.filter(characteristic => !characteristic.name.value && characteristic.description.value);
            const messages = [];
            if (characteristicsWithNoNameAndNoDescription.length > 0) {
                messages.push('error.fieldsControl.emptyCharacteristics');
            }
            if (characteristicsWithNameAndNoDescription.length > 0 || characteristicsWithNoNameAndDescription.length > 0) {
                messages.push('error.fieldsControl.emptyCharacteristicsValueOrDescription');
            }
            if (
                characteristicsWithNameAndNoDescription.length === 0 &&
                characteristicsWithNoNameAndDescription.length === 0 &&
                characteristicsWithNoNameAndNoDescription.length === 0
            ) {
                $scope.initConfirmationPopup ? initInternationalConfirmationLanguagePopUp($stateParams, $rootScope, spidModal, $scope, $scope.focusedSibling.publish) : $scope.smartSaveAndPublish();
            } else {
                messages.forEach(function (message) {
                    $translate(message).then(function (translation) {
                        toastService.displayToast(translation, 'error');
                    });
                });
            }
        };

        function buildSports(cardDto) {
            cardDto.sports.forEach(function (sport) {
                var sportCache = findSportById(sport.id);
                if (sportCache) {
                    _(sport).extend({
                        name: sportCache.name,
                        fatherId: sportCache.fatherId,
                        fatherLabel: sportCache.fatherName
                    });
                }
            });
            sortSports(cardDto.sports);
        }

        function sortSports(cardSports) {
            cardSports.sort(
                function (a, b) {
                    if (a.sportResponsible) {
                        return -1;
                    }
                    if (b.sportResponsible) {
                        return 1;
                    }
                    return (a.fatherLabel || '').localeCompare(b.fatherLabel || '');
                }
            );
        }

        $scope.productCardUserBenefits = $scope.productCardDto.userBenefits;
        $scope.productCardUserBenefits.forEach(function (userBenefit) {
            var userBenefitInCache = userBenefits.find(function (userBenefitCache) {
                return userBenefitCache.id === userBenefit.userBenefitId;
            });
            userBenefit.userBenefitCache = userBenefitInCache ? {
                title: userBenefitInCache.title,
                definition: userBenefitInCache.definition,
                technicalTitle: userBenefitInCache.technicalTitle,
                picto: userBenefitInCache.userBenefitPictoContentUrl,
                label: userBenefitInCache.label,
                locale: userBenefitInCache.locale,
                isActive: userBenefitInCache.isActive
            } : {};
        });

        // REBUILD PRODUCT NATURE

        var localGlobalFields = [
            'madeFor', 'madeForExtended',
            'teaser', 'webTeaser',
            'warrantyText', 'warrantyPeriod',
            'storageAdvice', 'test', 'agreeBy', 'userRestriction', 'legalNotice'
        ];

        var currentVersionTimeline = (timeline
            .versions || [])
            .find(version => version.versionDate === productCardDto.versionDate);
        $scope.currentVersionTimeline = currentVersionTimeline;
        var hasIntProduct = currentVersionTimeline.masterLocale.endsWith('INT');
        var allAvailableLocales = [...new Set(currentVersionTimeline
            .models
            .flatMap(model => model.locales)
        )];

        var futureVersionsTimeline = (timeline
            .versions || [])
            .filter(version => moment(version.versionDate).isAfter(productCardDto.versionDate));

        $scope.canEditStructuredData = $stateParams.smLocale.contains('INT') || !hasIntProduct;

        $scope.owlOptions = {
            loop: true,
            nav: true,
            navText: ['<', '>'],
            items: 3,
            dots: false
        };

        $scope.productCardSmartCopy = false;
        $scope.productCardReconduction = false;

        $scope.nbPublished = 0;
        $scope.nbEnCours = 0;
        $scope.isMaster = false;

        function newBenefitIsEcoConception() {
            _($scope.productCardUserBenefits).forEach(userBenefit => {
                if (userBenefit.id === null && userBenefit.userBenefitId === ecoDesignUserBenefitId) {
                    return true;
                }
            });
            return false;
        }

        function cleanCachedElements() {
            $scope.productCardDto.userBenefits = !newBenefitIsEcoConception() ? $scope.productCardUserBenefits :
                _($scope.productCardUserBenefits).filter(function (userBenefit) {
                    return userBenefit.userBenefitId !== ecoDesignUserBenefitId;
                });


            $scope.productCardDto.userBenefits.forEach(function (userBenefit) {
                delete userBenefit.userBenefitCache;
            });
            submitLocalGlobal(localGlobalFields);
        }

        function cleanEmptyStructurations() {
            $scope.productCardDto.segmentation.types = _($scope.productCardDto.segmentation.types).filter(
                function (structurationType) {
                    return structurationType.structurationValues
                        && structurationType.structurationValues.length > 0;
                }
            );
        }

        $scope.smartSaveAndPublish = function () {
            toastService.clearToast();
            var errorMessages = [];
            var paceModelsNotPublished = [];
            var readyToSave = true;

            /**
             * Si au moins un modèle est ou doit être publié, alors
             * il faut vérifier que tout au super modèle est nickel
             */
            var atLeastOneMustBePublished = $scope.siblings.find(function (sibling) {
                return sibling.published || sibling.metadatas.needToPublish;
            });

            errorMessages = _(errorMessages).concat(modelValidationService.checkSuperModelValidForSave($scope.productCardDto, $scope.genders, $scope.structurations, $scope.userBenefits, $scope.productCardUserBenefits));
            errorMessages = _(errorMessages).concat([

                modelValidationService.checkCharacteristics($scope.productCardDto),
            ]);

            if (($scope.isMaster && $scope.forceTaxonomyStructurations === 'true' && atLeastOneMustBePublished) || (!$scope.isMaster && $scope.forceTaxonomyStructurationsForLocales === 'true')) {
                errorMessages = _(errorMessages).concat(modelValidationService.checkStructurations($scope.productCardDto));
            }
            if (angular.isDefined(atLeastOneMustBePublished)) {
                errorMessages = _(errorMessages).concat([
                    modelValidationService.checkSuperModelValidForPublication($scope.productCardDto),
                    modelValidationService.checkMedias($scope.siblings)
                ]);
            }

            errorMessages = _.compact(errorMessages);

            readyToSave = readyToSave && _(errorMessages).isEmpty();

            if (!_(errorMessages).isEmpty()) {
                _(errorMessages).flatten().forEach(function (errorMessage) {
                    toastService.displayToast(errorMessage, 'error');
                });
            }

            $scope.siblings.forEach(function (sibling) {
                if (sibling.available) {

                    var errorFromPaceMessages = [];
                    errorMessages = [];
                    var forcedNeedToPublish = sibling.metadatas.needToPublish;

                    if (sibling.metadatas.paceColors) {
                        errorFromPaceMessages = _(errorFromPaceMessages).concat(modelValidationService.checkModelValidForSave(sibling, $scope.colorsRepository, $scope.fragrancesRepository, $scope.flavorsRepository));
                    }

                    if (sibling.metadatas.paceComposition) {
                        errorFromPaceMessages = _(errorFromPaceMessages).concat(modelValidationService.checkComposition(sibling, $scope.productPartsRepository, $scope.materialsRepository));
                    }

                    errorFromPaceMessages = _.compact(errorFromPaceMessages);
                    if (!_(errorFromPaceMessages).isEmpty()) {
                        _(errorFromPaceMessages).flatten().forEach(function (errorMessage) {
                            toastService.displayToast($filter('translate')('product.modelCode') + ' ' + sibling.code + ' ' + errorMessage + '. ' + $filter('translate')('product.dataFromPace'), 'error');
                        });

                        if (sibling.metadatas.needToPublish) {
                            paceModelsNotPublished = _(paceModelsNotPublished).concat(sibling);
                            forcedNeedToPublish = false;
                        }
                    }

                    if (!sibling.metadatas.paceColors) {
                        errorMessages = _(errorMessages).concat(modelValidationService.checkModelValidForSave(sibling, $scope.colorsRepository, $scope.fragrancesRepository, $scope.flavorsRepository));
                    }
                    if (!sibling.metadatas.paceComposition) {
                        errorMessages = _(errorMessages).concat(modelValidationService.checkComposition(sibling, $scope.productPartsRepository, $scope.materialsRepository));
                    }
                    //errorMessages = _(errorMessages).concat(modelValidationService.checkOldComposition(sibling, $scope.oldCompositionMaxSize));

                    if (sibling.published || sibling.metadatas.needToPublish) {
                        /**validation*/
                        errorMessages = _(errorMessages).concat(modelValidationService.checkDeclinaisons(sibling));
                        errorMessages = _(errorMessages).concat(modelValidationService.checkModelValidForPublication(sibling));

                    }
                    errorMessages = _.compact(errorMessages);

                    if (!_(errorMessages).isEmpty()) {
                        _(errorMessages).flatten().forEach(function (errorMessage) {
                            toastService.displayToast($filter('translate')('product.modelCode') + ' ' + sibling.code + ' ' + errorMessage, 'error');
                        });
                    }

                    sibling.metadatas.needToPublish = forcedNeedToPublish;

                    readyToSave = readyToSave && _(errorMessages).isEmpty();
                }
            });
            if (readyToSave) {
                $scope.productCardDto.models = _($scope.productCardDto.models)
                    .chain()
                    .filter(function (model) {
                        return model.available;
                    })
                    .each(function (model) {
                        if ($scope.isMaster) {
                            mediaService.checkForBalisage(model.medias);
                        }
                    })
                    .map(function (model) {
                        return modelValidationService.getCleannedFields(model, userSettings.getUser().uid);
                    })
                    .value();

                var modelCodes = _(paceModelsNotPublished).chain().map(function (model) {
                    return model.code;
                }).value();

                // Affiche une popup si la locale de l'utilisateur est différente de la langue d'initialisation d'une fiche
                if ($scope.productCardDto.locale === 'int_INT' &&
                    $scope.initLocale.substring(0, 2) !== userSettings.getSelectedWorkingLocale().substring(0, 2)) {
                    var confirmLocaleModal = spidModal
                        .open({
                            templateUrl: 'productCardNg/popup/localeConfirmationPopup.html',
                            scope: $scope
                        });
                    confirmLocaleModal.result
                        .then(function () {
                            if (!_(modelCodes).isEmpty()) {
                                var messageText = $filter('translate')('product.publish.first.message.invalidPaceDatas') + _(modelCodes).value().join(',');
                                dialogService.showMessageBox('product.publish.first.popup.paceDatas.title', messageText).result.then(function () {
                                    checkFuturesVersionsAndPropagation($scope.pristineProductCardDto, $scope.productCardDto);
                                });
                            } else {
                                checkFuturesVersionsAndPropagation($scope.pristineProductCardDto, $scope.productCardDto);
                            }
                        }, function () {

                        });
                    confirmLocaleModal.closed.then(function () {
                        confirmLocaleModal = null;
                    });

                } else {
                    if (!_(modelCodes).isEmpty()) {
                        var messageText = $filter('translate')('product.publish.first.message.invalidPaceDatas') + _(modelCodes).value().join(',');
                        dialogService.showMessageBox('product.publish.first.popup.paceDatas.title', messageText).result.then(function () {
                            checkFuturesVersionsAndPropagation($scope.pristineProductCardDto, $scope.productCardDto);
                        });
                    } else {
                        checkFuturesVersionsAndPropagation($scope.pristineProductCardDto, $scope.productCardDto);
                    }
                }
            }
        };

        /**
         * Checks if there is at least a future version that can be candidate for the propagation related to the datas to propagate.
         * If there is at least a data of sm, we check only if there is a future version of the sm.
         * If there is only datas for models, we check if there is at least a future version of one of the modified models
         * @param pristineModel
         *          the source sm
         * @param targetSuperModel
         *          the sm modified
         */
        function checkFuturesVersionsAndPropagation(pristineModel, targetSuperModel) {
            var comparedPristineModel = angular.copy(pristineModel);
            var comparedTargetSuperModel = angular.copy(targetSuperModel);
            var datasToPropagateForSm = propagationService.getDatasToPropagateForSm(comparedPristineModel, comparedTargetSuperModel);
            var datasToPropagateForModels = propagationService.getDatasToPropagateForModels(comparedPristineModel, comparedTargetSuperModel);
            var strictModelsCheck = false;
            var somethingChanged = false;
            if (!datasToPropagateForSm.somethingHasChanged) {
                if (!datasToPropagateForModels.somethingHasChanged) {
                    // Si rien n'a été modifié, on ne propose pas la popup de propagation et on sauvegarde
                    saveSuperModel('ENREG_PUB', false);
                } else {
                    strictModelsCheck = true;
                    somethingChanged = true;
                }
            } else {
                somethingChanged = true;
            }
            // TODO: quelle locale utiliser ? la locale de l'objet sm ou celle du stateParam ?
            if (somethingChanged && !propagationModal) {
                // On récupère seulement les codes modèles qui ont des données à propager
                var modelsCodesModified = _.pick(datasToPropagateForModels.datasToPropagate, function (value) {
                    return !_.isEmpty(_.omit(value, 'modelCode'));
                });
                var propagationLocale = $stateParams.initLocale || $stateParams.smLocale;
                // Si il existe une version plus récente, on propose la modal de propagation, sinon on sauvegarde directement
                var hasFutureVersion = futureVersionsTimeline.some(futurVersion =>
                    // current locale exists in future
                    futurVersion.models.flatMap(m => m.locales).indexOf(productCardDto.locale) !== -1
                    // and also the a modified model
                    && (!strictModelsCheck || _(futurVersion.models).intersection(modelsCodesModified).length > 0)
                );
                if (hasFutureVersion) {
                    displayPropagationModal(pristineModel.smVersionId, propagationLocale, datasToPropagateForSm.datasToPropagate, datasToPropagateForModels.datasToPropagate);
                } else {
                    saveSuperModel('ENREG_PUB', false);
                }
            }
        }

        /**
         * Displays the propagation modal
         * @param smVersionId
         *          the id of the smVersion to propagate or not
         * @param locale
         *          the locale for the propagation
         * @param datasToPropagateForSm
         *          objects that contains the set of datas to propagate or not for sm
         * @param datasToPropagateForModels
         *          objects that contains the set of datas to propagate or not for models
         */
        function displayPropagationModal(smVersionId, locale, datasToPropagateForSm, datasToPropagateForModels) {
            propagationModal = spidModal.open({
                templateUrl: 'productCardNg/propagation/propagation.html',
                controller: 'PropagationController',
                resolve: {
                    smVersionId: function () {
                        return smVersionId;
                    },
                    locale: function () {
                        return locale;
                    },
                    datasToPropagateForSm: function () {
                        return datasToPropagateForSm;
                    },
                    datasToPropagateForModels: function () {
                        return datasToPropagateForModels;
                    }
                }
            });
            propagationModal.result.then(function (result) {
                if (result && result.mustPropagate) {
                    if (!_.isNull(result.propagationRequest)) {
                        result.propagationRequest.userId = userSettings.getUser().uid;
                        result.propagationRequest.userLocale = userSettings.getSelectedIhmLocale();
                    }
                    saveSuperModel('ENREG_PUB_PROBA', result.mustPropagate, result.propagationRequest);
                } else {
                    saveSuperModel('ENREG_PUB_NOPROBA', false);
                }
            });
            propagationModal.closed.then(function () {
                propagationModal = null;
            });
        }

        /**
         * Allows to save a superModel and its siblings
         * @param superModelSeasonEventType
         *          string Type of event to register
         * @param mustPropagate
         *          boolean if we must propagate the saved datas or not
         * @param propagationRequest
         *          object that contains informations needed for the propagation process
         */
        function saveSuperModel(superModelSeasonEventType, mustPropagate, propagationRequest) {
            cleanCachedElements();
            cleanEmptyStructurations();
            superModelService.saveProductCard($scope.productCardDto, $stateParams.smCode, $stateParams.versionDate, $stateParams.initLocale || $stateParams.smLocale, $scope.workingLocale.locale).then(function (listModelsToLog) {
                if ($rootScope.somethingChanged) {
                    var modelCodesToLog = listModelsToLog.data.join(',');
                    superModelService.saveSuperModelSeasonEvent($scope.productCardDto.smVersionId, $stateParams.smLocale, superModelSeasonEventType, modelCodesToLog);
                }

                $rootScope.somethingChanged = false;

                if (angular.isDefined($stateParams.initLocale)) {
                    removeOpenedItem($stateParams.modelCode, $stateParams.smLocale);

                    var stateParams = angular.copy($stateParams);
                    stateParams.smLocale = stateParams.initLocale;
                    stateParams.initLocale = undefined;
                    stateParams.modelCode = $scope.selectedModelCode;
                    $state.go('productCardNg', stateParams).then(function () {
                        toastService.displayTimedToast($filter('translate')('product.save.successfully'), 'success');
                    });
                } else {
                    stateParams = angular.copy($stateParams);
                    stateParams.modelCode = $scope.selectedModelCode;
                    $state.transitionTo($state.current, stateParams, {
                        reload: true, inherit: false, notify: true
                    }).then(function () {
                        toastService.displayTimedToast($filter('translate')('product.save.successfully'), 'success');
                    });
                }

                if (mustPropagate) {
                    propagationService.propagate(propagationRequest)
                        .then(function (data) {
                            if (!data || data.status !== 200) {
                                toastService.displayTimedToast($filter('translate')('product.propagation.error'), 'error');
                            }
                        }, function () {
                            toastService.displayTimedToast($filter('translate')('product.propagation.error'), 'error');
                        });
                }

                if ($scope.productCardReconduction) {
                    $analytics.eventTrack('Reconduction au SM', {
                        category: 'Fiche SM',
                        label: 'Reconduction du SM ' + $scope.productCardDto.code
                    });
                }
                if ($scope.productCardSmartCopy) {
                    $analytics.eventTrack('Smart copy', {category: 'Fiche SM', label: 'Smart copy'});
                }

                $analytics.eventTrack('Smart button', {category: 'Fiche SM', label: 'Sauvegarde d\'une fiche'});

            });
        }


        $scope.focusSibling = function (sibling) {
            $scope.focusedSibling = sibling;
            if ($scope.focusedSibling && $scope.focusedSibling.available) {
                mediaService.modelPackshotChanged($scope);
                mediaService.wrapModelMedias($scope.focusedSibling.medias);
                $scope.selectedModelCode = $scope.focusedSibling.code;
            }

            var firstPackshotsCarousel = angular.element('#firstPackshotsCarousel');
            if (firstPackshotsCarousel.length > 0) {
                var toIndex = _($scope.siblings).indexOf(sibling);
                firstPackshotsCarousel.trigger('to.owl.carousel', [toIndex, 1, true]);
            }
            if (sibling.available) {
                //$location.search('modelCode', sibling.code);
            }
        };
        $scope.$on('inputModified.formChanged', function (event, modified/*, formCtrl*/) {
            $rootScope.somethingChanged = modified;
        });
        $scope.allLanguages = languages;
        $scope.superModel = productCardDto;

        // Initialialisation des données locale/globale
        if (!(productCardDto.locale).contains('INT')) {
            initLocaleGlobale(localGlobalFields);
        }


        $scope.model = $scope.productCardDto; // Retro-compatibilité

        if (productCardDto.codeRef !== null) {
            $scope.productBriefReferenceUrl = envService.getProductBriefReferenceUrl() + productCardDto.codeRef;

            var minDate, maxDate;
            $scope.productCardDto.models.forEach(function (sibling) {
                minDate = minDate === undefined ? sibling.worldStartDate : minDate > sibling.worldStartDate ? sibling.worldStartDate : minDate;
                maxDate = maxDate === undefined ? sibling.worldEndDate : maxDate < sibling.worldEndDate ? sibling.worldEndDate : maxDate;
            });

            minDate = minDate !== undefined ? encodeURIComponent(moment.utc(minDate).format('YYYY-MM-DDTHH:mm:ss')) : null;
            maxDate = maxDate !== undefined ? encodeURIComponent(moment.utc(maxDate).format('YYYY-MM-DDTHH:mm:ss')) : null;

            $scope.spoUpdateSmUrl = envService.getSpoUpdateSmUrl() +
                '?world_date_from=' + minDate +
                '&world_date_to=' + maxDate +
                '&superModel=' + productCardDto.codeRef;
        }

        productNatureService.getProductNature(productCardDto.productNature.id, 'en_GB').then(function (productNature) {
            $scope.productNatureEN = productNature;
        });
        productNatureService.getProductNature(productCardDto.productNature.id, 'fr_FR').then(function (productNature) {
            $scope.productNatureFR = productNature;
        });
        $scope.smAvailableLanguages = allAvailableLocales;
        $scope.sortAvailableLocales = function (availableLanguage) {
            return $scope.allLanguages[availableLanguage];
        };
        $scope.materialsRepository = materialsRepository;

        $scope.productPartsRepository = productPartsRepository;
        $scope.colorsRepository = colorsRepository;
        $scope.flavorsRepository = flavorsRepository;
        $scope.fragrancesRepository = fragrancesRepository;
        parameterService.getParameter('core', 'oldCompoMaxSize').then(function (resp) {
            $scope.oldCompositionMaxSize = resp;
        });

        parameterService.getParameter('feature', 'taxonomy.forceMandatory').then(function (resp) {
            $scope.forceTaxonomyStructurations = resp;
        });

        parameterService.getParameter('feature', 'taxonomy.forceMandatoryForLocale').then(function (resp) {
            $scope.forceTaxonomyStructurationsForLocales = resp;
        });

        $scope.previousSmSeason = previousSmSeason;
        $scope.genders = genders;
        $scope.sportsRepository = sportsRepository;
        $scope.data = {selectedModelLanguage: $stateParams.smLocale};
        $scope.structurations = structurations;
        $scope.properties = {pixlPanelVisible: null};
        $scope.userBenefits = userBenefits;
        $scope.careInstructionsRepository = careInstructionsRepository;
        $scope.careSymbolsRepository = careSymbolsRepository;
        $scope.warningsRepository = warningsRepository;
        $scope.allSaisonTab = [];
        $scope.versionDate = null;
        $scope.productCardDto.models.forEach(function (sibling) {
            if ($scope.versionDate === null || $scope.versionDate > sibling.productSellingStartWeek) {
                $scope.versionDate = sibling.productSellingStartWeek;
            }
        });

        $scope.currentVersion = {versionDate: new Date(0)};
        (timeline.versions || []).forEach(ver => {
            const currentVersion = new Date($scope.currentVersion.versionDate);
            const version = new Date(ver.versionDate);
            const isVersionPublished = ver.models.some(model => model.published);

            if (version > currentVersion && version <= new Date() && isVersionPublished) {
                $scope.currentVersion = ver;
            }
        });

        // Récupération de la liste des versions pour la product card courante.
        let versionsArray = [];
        (timeline.versions || []).forEach(aVersion => {
            if ($scope.productCardDto.versionDate === aVersion.versionDate) {
                $scope.version = aVersion;
            }
            versionsArray.push(aVersion);
        });
        $scope.versions = versionsArray || [];

        $scope.currentLocaleOrVersionDefaultLocale = function (currentLocale, version) {
            if (version.existingLocales.indexOf(currentLocale) > -1) {
                return currentLocale;
            } else {
                return version.masterLocale;
            }
        };

        // prevent to modify past season
        superModelService.getCurrentModelSeasons($scope.productCardDto.smId, $scope.productCardDto.versionDate, $scope.productCardDto.locale).then(function (response) {
            {
                if (response.data.length === 0) {
                    $scope.hasRoleCUD = false;
                    $scope.allModelInFuture = true;
                }
                _($scope.productCardDto.models).each(function (sibling) {
                    sibling.existInFuture = response.data.indexOf(sibling.code) === -1;
                });
            }

        }).catch(function (err) {
            console.log('currentSeason error : ', err);
        });


        function getFirstAvailableSibling() {
            return _($scope.siblings).find(function (sibling) {
                return sibling.available;
            });
        }

        function submitLocalGlobal() {
            _(localGlobalFields).each(function (field) {
                if (productCardDto[field].override === productCardDto[field].value) {
                    productCardDto[field].override = null;
                }
            });
        }

        function initLocaleGlobale() {
            _(localGlobalFields).each(function (field) {
                if (!isSpeLocal() && (productCardDto[field].override == null || productCardDto[field].override === undefined)) {
                    productCardDto[field].override = productCardDto[field].value;
                }
            });
        }


        function initLastOpenedItem() {

            var firstAvailableSibling = getFirstAvailableSibling();

            var lastOpenedItem = {
                firstPackShotWeb: firstAvailableSibling.firstPackShotWeb,
                versionDate: $scope.productCardDto.versionDate,
                conceptionCode: $scope.productCardDto.codeConception,
                smCode: $scope.productCardDto.code,
                smLabel: $scope.productCardDto.locale === 'en_INT' ? $scope.productCardDto.labels.en : $scope.productCardDto.labels.fr,
                locale: $stateParams.smLocale,
                modelCode: firstAvailableSibling.code,
                smSeasonId: $scope.productCardDto.smSeasonId,
                date: new Date()
            };

            var existingLastOpenedItem = _($scope.lastOpenedItems).find(function (openedItem) {
                return openedItem.modelCode === lastOpenedItem.modelCode && openedItem.locale === lastOpenedItem.locale;
            });

            if (existingLastOpenedItem === undefined) {
                $scope.lastOpenedItems.push(lastOpenedItem);
            } else if (existingLastOpenedItem.locale !== lastOpenedItem.locale) {
                $scope.lastOpenedItems.splice($scope.lastOpenedItems.indexOf(existingLastOpenedItem), 1);
                $scope.lastOpenedItems.push(lastOpenedItem);
            } else {
                existingLastOpenedItem.date = new Date();
            }
        }

        function removeOpenedItem(code, locale) {
            var lastOpenedItemToRemove = _($scope.lastOpenedItems).find(function (lastOpenedItem) {
                return lastOpenedItem.modelCode === code && lastOpenedItem.locale === locale;
            });

            lastOpenedItemToRemove && $scope.lastOpenedItems.splice($scope.lastOpenedItems.indexOf(lastOpenedItemToRemove), 1);
        }

        $scope.selectLanguage = function () {

            var currentLocale = $stateParams.smLocale;
            if ($scope.data.selectedModelLanguage === currentLocale) {
                return;
            }

            var openProductCard = function () {
                $analytics.eventTrack('Changement de locale', {
                    category: 'Fiche SM',
                    label: 'Changement de locale de ' + currentLocale + ' vers ' + $scope.data.selectedModelLanguage
                });
                $state.go('productCardNg', {
                    smCode: $stateParams.smCode,
                    versionDate: $stateParams.versionDate,
                    smLocale: $scope.data.selectedModelLanguage,
                    modelCode: $scope.selectedModelCode
                });
            };

            var firstEditors = _.uniq(_.pluck($scope.siblings, 'firstEditor'));
            if ($scope.data.selectedModelLanguage.indexOf('INT') > -1 && (userSettings.hasRole('ROLE_CUD') || userSettings.hasRole('ROLE_CUD_INT')) && !_.contains(firstEditors, userSettings.getUser().uid)) {
                dialogService.showConfirmBox('product.sm.search.open_international_product.popup.title', 'product.sm.search.open_international_product.popup.message').result.then(
                    function () {
                        openProductCard();
                    },
                    function () {
                        $scope.data.selectedModelLanguage = currentLocale;
                    }
                );
            } else {
                openProductCard();
            }

        };

        $scope.selectVersion = function (version) {
            var initLocale;
            var currentLocale = $stateParams.smLocale;
            var initPopUp = function () {
                var internationalModal = spidModal.open({
                    templateUrl: 'search/internationalModal/internationalModal.html',
                    controller: 'internationalModal',
                    controllerAs: 'vm',
                    size: 'md'
                });
                internationalModal.result.then(function (popupData) {
                    if (popupData.rememberMyChoice) {
                        userSettings.addUserAdditionalProperty('internationalLocaleChoice', popupData.selectedLocalesInternationalMapping).then(function () {
                            initLocale = popupData.selectedLocalesInternationalMapping;
                            $scope.data.selectedModelLanguage = initLocale;
                            openProductCard(initLocale);
                        });
                    } else {
                        initLocale = popupData.selectedLocalesInternationalMapping;
                        $scope.data.selectedModelLanguage = 'int_INT';
                        openProductCard(initLocale);
                    }
                })
                    .catch(console.error);
            };
            var openProductCard = function () {
                $analytics.eventTrack('Changement de saison', {
                    category: 'Fiche SM',
                    label: 'Changement de saison de ' + currentLocale + ' vers ' + $scope.data.selectedModelLanguage
                });
                $state.go('productCardNg', {
                    smCode: $stateParams.smCode,
                    versionDate: version.versionDate,
                    smLocale: $scope.data.selectedModelLanguage,
                    modelCode: $scope.selectedModelCode,
                    initLocale: initLocale
                });
            };
            // Necessary to switch between unintialized seasons
            if ($scope.data.selectedModelLanguage === 'int_INT' && $scope.initLocale) {
                $scope.data.selectedModelLanguage = $scope.initLocale;
                openProductCard($scope.initLocale);
            }
            if (version.existingLocales.indexOf($scope.data.selectedModelLanguage) === -1) {
                $scope.data.selectedModelLanguage = version.existingLocales.find(aLocale => aLocale.endsWith('INT'));
                openProductCard(initLocale);
            }
            // from initialized season to unitialized season
            if (version.unInit) {
                initPopUp();
                // Switch to initialized season
            } else {
                initLocale = null;
                openProductCard(initLocale);
            }
        };

        function getFirstPackshotWeb(modelCode) {

            var firstPackshotWeb = _($scope.productCardDto.models)
                .chain()
                .filter(function (model) {
                    return model.code === modelCode;
                })
                .map(function (model) {
                    return model.medias.masterModelPackshots;
                })
                .flatten()
                .filter(function (siblingMasterPackshot) {
                    return siblingMasterPackshot.zone === 'PRODUCT';
                })
                .sortBy(function (siblingMasterPackshot) {
                    return siblingMasterPackshot.order;
                })
                .first()
                .value();

            return firstPackshotWeb && wediaService.getMediaURL(firstPackshotWeb);
        }

        let initGender = function () {
            $scope.genders = genderService.setSelectableGenders($scope.genders);
        };

        var hasSportResponsible = function () {
            var result = false;
            _($scope.productCardDto.sports).forEach(function (sport) {
                if (sport.sportResponsible === true) {
                    result = true;
                }
            });
            return result;
        };

        var initSiblings = function () {
            var siblings = $scope.productCardDto.models;
            siblings.forEach(function (sibling) {
                sibling.available = sibling.metadatas.hasModelVersionLocalized && !(moment.utc(sibling.worldStartDate) > moment('9999', 'YYYY'));
            });
            var pristineSiblings = [];

            var promises = [];

            $scope.focusedSibling = _(siblings).find(function (sibling) {
                return sibling.code === $stateParams.modelCode;
            });

            var initialSlide = null;

            $scope.isMaster = siblings.reduce(function (isMaster, sibling) {
                return isMaster || sibling.master;
            }, false);

            _(siblings).each(function (sibling, index) {
                if (sibling.available) {
                    sibling.image = getFirstPackshotWeb(sibling.code);
                    sibling.selected = sibling.code === $stateParams.modelCode;
                    $scope.owlOptions.startSlide = (sibling.code === $stateParams.modelCode ? index : initialSlide);
                    initSiblingData(sibling);

                    var mediasBefore = angular.copy(sibling.medias);
                    mediaService.fixMediaDisplayOrder(sibling.medias);

                    sibling.medias.hadTechnicalReorder = !angular.equals(sibling.medias, mediasBefore);

                    sibling.published ? $scope.nbPublished++ : $scope.nbEnCours++;

                } else {
                    promises.push(mediaService.getMasterModelPackshots(sibling.modelSeasonId));
                }
                pristineSiblings.push(angular.copy(sibling));
            });

            $scope.owlOptions.loop = siblings.length > 3;

            if (!$scope.focusedSibling && siblings.length > 0) {
                $scope.focusedSibling = siblings[0];
                $scope.focusSibling($scope.focusedSibling);
            }
            return $q.all(promises).then(function (responses) {

                _(siblings).chain().filter(function (sibling) {
                    return !sibling.available;
                }).each(function (sibling, index) {
                    var masterModelPackshots = responses[index];
                    var firstPackshot = masterModelPackshots && masterModelPackshots.length > 0 ? masterModelPackshots[0] : null;
                    if (firstPackshot) {
                        firstPackshot.type = 'PACKSHOT';
                        sibling.image = wediaService.getMediaURL(firstPackshot);
                    } else {
                        sibling.image = 'common/styles/img/no-picture.jpg';
                    }
                });

                $scope.pristineProductCardDto = angular.copy($scope.productCardDto);

                $scope.pristineSiblings = pristineSiblings;
                $scope.siblings = siblings;

                initLastOpenedItem();
            });
        };

        $scope.init = function () {
            toastService.clearToast();

            initGender();

            initSiblings().then(function () {
                var firstEditors = _.uniq(_.pluck($scope.siblings, 'firstEditor'));
                if ($scope.productCardDto.locale !== 'int_INT' && $scope.productCardDto.locale.indexOf('INT') > -1 && (userSettings.hasRole('ROLE_CUD') || userSettings.hasRole('ROLE_CUD_INT')) && !_.contains(firstEditors, userSettings.getUser().uid)) {
                    toastService.displayTimedToast($filter('translate')('product.sm.search.open_international_product.popup.message'), 'warning', 5000, 'toast-' + $scope.productCardDto.locale);
                }
                $timeout(function () {

                    var errorMessages = _.compact([
                        modelValidationService.checkAndAlertInactiveValues($scope.productCardDto, $scope.genders, $scope.structurations, $scope.userBenefits, $scope.colorsRepository, $scope.fragrancesRepository, $scope.flavorsRepository),
                    ]);

                    if (!_(errorMessages).isEmpty()) {
                        _(errorMessages).forEach(function (errorMessage) {
                            toastService.displayToast(errorMessage, 'error');
                        });
                    }

                    $scope.siblings.forEach(function (sibling) {
                        errorMessages = [];
                        if (sibling.available) {
                            if (sibling.metadatas.paceColors) {
                                errorMessages = _(errorMessages).concat(modelValidationService.checkModelValidForSave(sibling, $scope.colorsRepository, $scope.fragrancesRepository, $scope.flavorsRepository));
                            }
                            if (sibling.metadatas.paceComposition) {
                                errorMessages = _(errorMessages).concat(modelValidationService.checkComposition(sibling, $scope.productPartsRepository, $scope.materialsRepository));
                            }
                            errorMessages = _.compact(errorMessages);

                            if (!_(errorMessages).isEmpty()) {
                                _(errorMessages).flatten().forEach(function (errorMessage) {
                                    toastService.displayToast($filter('translate')('product.modelCode') + ' ' + sibling.code + ' ' + errorMessage + '. ' + $filter('translate')('product.dataFromPace'), 'error');
                                });
                            }
                        }
                    });

                    if (!$scope.canEdit) {
                        toastService.displayToast($filter('translate')('product.migratedLocale') + ' ' + $scope.localeLabUrl, 'error');
                    }

                    if ($stateParams.initLocale != null) {
                        toastService.displayTimedToast($filter('translate')('product.international_mapping.you_are_currently_initializing_an_international_product'), 'info', 5000);

                        if ($scope.hasRoleCUD && $scope.previousSmSeason != null) {
                            doReconduction(true, null);
                        }
                    }
                });
            });

            $scope.hasSportResponsible = hasSportResponsible();
        };

        function initSiblingData(sibling) {
            materialService.hasCorrectProductPercents(sibling.composition.partOfProducts);
            sibling.metadatas.needToPublish = false;
            modelService.cleanDimensions($scope.productCardDto.productNature, sibling);
        }


        $scope.languageIsAvailable = function (allAvailableLanguages, language) {
            return allAvailableLanguages && allAvailableLanguages.indexOf(language) > -1;
        };

        $scope.setTitleAvailable = function (allAvailableLanguages, language) {
            if ($scope.languageIsAvailable(allAvailableLanguages, language)) {
                return '';
            }
            return 'product.available.for.others.tooltip';
        };

        var reconduireCopierModal = null;

        $scope.openReconduireCopierModal = function () {
            if (!reconduireCopierModal) {
                smartCopyModal = spidModal.open({
                    templateUrl: 'productCardNg/reconduireCopier/reconduireCopier.html',
                    controller: 'ReconduireCopierController',

                    resolve: {
                        sourceSuperModel: function () {
                            return $scope.productCardDto;
                        },
                        countries: function () {
                            return countriesService.getCountries(userSettings.getSelectedIhmLanguage().toUpperCase());
                        }
                    }
                });
                smartCopyModal.result.then(function (result) {
                    doReconduction(false, result);
                });
                smartCopyModal.closed.then(function () {
                    smartCopyModal = null;
                });

            }
        };

        function doReconduction(silent, result) {
            if (!silent) {
                $scope.productCardReconduction = true;
            }
            var previewSeason = null;
            var smVersionId = null;
            var local = null;
            // il n'as pas de preview season donc il faut prevoir le cas ou $scope.previousSmSeason=null.
            if ($scope.previousSmSeason && $scope.previousSmSeason.smVersionId && $scope.previousSmSeason.locale) {
                previewSeason = $scope.previousSmSeason;
                smVersionId = $scope.previousSmSeason.smVersionId;
                local = $scope.previousSmSeason.locale;
            }
            // Il y a potentiellement des changements de famille, on ne filtre donc pas sur la famille
            if (result) {
                $scope.previousSmSeason = result.smSeason;
                previewSeason = $scope.previousSmSeason;
                smVersionId = result.smSeason.id;
                local = result.locale;
            }
            superModelService.getSuperModelSeasonInfo(smVersionId, (previewSeason && local) || $stateParams.smLocale).then(function (response) {
                var previousSuperModelSeason = response.data;

                buildSports(previousSuperModelSeason);

                _(previousSuperModelSeason.models).each(function (previousSibling) {
                    initSiblingData(previousSibling);
                });
                // Not local global data
                var overrideSMProps = [
                    'definitionCategory',
                    'genders',
                    'userBenefits', 'characteristics', 'segmentation',
                    'notice', 'careInstructions', 'careSymbols'
                ];

                // Local global data
                var overrideSMLocalGlobalProps = [
                    'madeFor', 'madeForExtended',
                    'teaser', 'webTeaser',
                    'warrantyText', 'warrantyPeriod',
                    'storageAdvice', 'test', 'agreeBy', 'userRestriction', 'legalNotice'
                ];

                _(overrideSMProps).each(function (prop) {
                    $scope.productCardDto[prop] = previousSuperModelSeason[prop];
                });

                _(overrideSMLocalGlobalProps).each(function (prop) {
                    $scope.productCardDto[prop] = previousSuperModelSeason[prop];
                });

                $scope.productCardDto.reconductionManually = $scope.model.reconductionManually = true;


                _($scope.siblings).each(function (sibling) {
                    var previousSibling = _(previousSuperModelSeason.models).find(function (onePreviousSibling) {
                        return onePreviousSibling.code === sibling.code;
                    });

                    if (previousSibling) {
                        copySiblingProperties(sibling, previousSibling);
                    }
                    if (sibling.medias.masterModelPackshots.length > 0 || sibling.medias.modelMedia360.length > 0 || sibling.medias.modelPackshots.length > 0
                        || sibling.medias.modelMediaFilms.length > 0) {
                        sibling.$somethingChanged = true;
                    }

                });
                mediaService.modelPackshotChanged($scope);
                mediaService.wrapModelMedias($scope.focusedSibling.medias);
                mediaService.retrieveObsoleteMediasReconduction($scope.focusedSibling);
                mediaService.retrieveObsoleteMedias($scope.productCardDto.models);
                $scope.imagesFields.modified = true;
                $scope.$broadcast('reconduction');
                if (!silent) {
                    toastService.displayToast($filter('translate')('product.reconduction_running_and_pending_until_validation'), 'info');
                }
            });
        }


        function copySiblingProperties(targetSibling, sourceSibling) {
            var overrideSiblingProps = [
                'modelInfoName', 'webLabel', 'colors', 'flavors', 'fragrance',
                'composition', 'nutritionTable', 'productComposing', 'medias', 'packaging', 'weightContext', 'items'
            ];

            _(overrideSiblingProps).each(function (prop) {
                targetSibling[prop] = sourceSibling[prop];
            });
        }

        $scope.cancelReconduction = function () {
            $state.reload().then(function () {
                $rootScope.somethingChanged = false;
            });
        };

        $scope.openDeleteManualCreationModal = () => {
            var modalInstance = spidModal.open({
                templateUrl: 'dialogs/deleteVersion/confirmDeleteVersion.html',
                controller: 'ConfirmDeleteVersionController',
                size: 'md',
                resolve: {
                    versionDate: () => productCardDto.versionDate,
                    locale: () => productCardDto.locale,
                    code: () => productCardDto.code,
                    modelCodes: () => productCardDto.models.map(model => model.code)
                }
            });

            modalInstance.result.then(() => {
                    versionService.deleteManualCreation(
                        productCardDto.code
                    ).then(() => lastSearchService.goToLastSearch());
                },
                angular.noop);

            modalInstance.closed.then(() => modalInstance = null);
        };

        $scope.openDeleteVersionModal = () => {
            var modalInstance = spidModal.open({
                templateUrl: 'dialogs/deleteVersion/confirmDeleteVersion.html',
                controller: 'ConfirmDeleteVersionController',
                size: 'md',
                resolve: {
                    versionDate: () => productCardDto.versionDate,
                    locale: () => productCardDto.locale,
                    code: () => productCardDto.code,
                    modelCodes: () => productCardDto.models.map(model => model.code)
                }
            });

            modalInstance.result.then(() => {
                    versionService.deleteVersion(productCardDto.code,
                        productCardDto.versionDate,
                        productCardDto.locale,
                        productCardDto.models.map(model => model.code),
                        userSettings.getSelectedWorkingLocale()
                    ).then(() => lastSearchService.goToLastSearch());
                },
                angular.noop);

            modalInstance.closed.then(() => modalInstance = null);
        };

        $scope.canDeleteManualCreation = () => !currentVersionTimeline.models.some(model => model.published);

        $scope.canDeleteVersion = () => !currentVersionTimeline.models.some(model => model.published) &&
            timeline.versions.some(version => moment(version.versionDate).isBefore(currentVersionTimeline.versionDate));

        var addPictoModal;

        $scope.displayAddPictoDlg = function () {
            if (!addPictoModal) {
                addPictoModal = spidModal.open({
                    templateUrl: 'dialogs/mediaChooser/mediaChooser.html',
                    controller: 'MediaController',
                    size: 'lg',
                    resolve: {
                        pictures: function () {
                            return brandService.getBrandLogos($scope.productCardDto.brand.id);
                        },
                        pictureSelected: function () {
                            return $scope.productCardDto.brand.logoPath;
                        },
                        category: function () {
                            return 'BRAND';
                        },
                        contextURL: function () {
                            return envService.getBrandURL() + '/' + $scope.productCardDto.brand.id + '/logos';
                        },
                        canUploadPictures: function () {
                            return $scope.isAdmin;
                        }
                    }
                });
                addPictoModal.result.then(function (picture) {
                    $scope.productCardDto.brand.logoPath = picture;
                    $analytics.eventTrack('Changement de logo de marque', {
                        category: 'Fiche SM',
                        label: 'Changement de marque logo de marque'
                    });
                });

                addPictoModal.closed.then(function () {
                    addPictoModal = null;
                });
            }
        };

        $scope.makeBold = function () {
            $document.context.execCommand('bold', false, '');
        };

        $scope.checkDisabledState = function (expectedRole) {
            // disable if product wasn't created manually or if user doesn't have expected role
            const isCreaManu = productCardDto.fromManualCreation;
            const isAdmin = userSettings.hasRole('ROLE_ADMIN');
            const isRole = userSettings.hasRole(expectedRole);

            return !(isAdmin || (isCreaManu && isRole));
        };

        var smartCopyModal;
        $scope.displaySmartCopyDlg = function (sibling) {

            if (!smartCopyModal) {
                smartCopyModal = spidModal.open({
                    templateUrl: 'dialogs/smartCopy/smartCopy.html',
                    controller: 'SmartCopyController',
                    resolve: {
                        sourceModel: function () {
                            return sibling;
                        },
                        countries: function () {
                            return countriesService.getCountries(userSettings.getSelectedIhmLanguage().toUpperCase());
                        }
                    }
                });
                smartCopyModal.result.then(function (result) {
                    prepareSmartCopy(sibling, result.modelCode, result.locale, result.smSeasonId);
                });
                smartCopyModal.closed.then(function () {
                    smartCopyModal = null;
                });
            }
        };

        function prepareSmartCopy(model, modelCode, locale, smSeasonId) {


            var promises = [];
            promises.push(modelService.getModelData(modelCode, locale, smSeasonId).then(function (response) {
                return response.data;

            }));

            $q.all(promises).then(function (responses) {

                var smartCopyModel = responses[0];
                copySiblingProperties(model, smartCopyModel);

                var medias = responses[0].medias;
                mediaService.wrapModelMedias(medias);

                var selectedSibling = _($scope.productCardDto.models).find(function (sibling) {
                    return sibling.code === model.code;
                });

                selectedSibling.medias = sortModelMediasAndFixOrders(medias);

                model.smartCopy = true;

                $scope.$broadcast('smartCopy');

                toastService.displayToast($filter('translate')('product.smart_copy_running_and_pending_until_validation'), 'info');
                updateSmartCopyStatus();
            });
        }


        $scope.cancelSmartCopy = function (model) {
            var pristineSibling = _($scope.pristineSiblings).find(function (sibling) {
                return sibling.alfrescoId === model.alfrescoId;
            });
            copySiblingProperties(model, pristineSibling);

            var pristineModelWrapper = _($scope.pristineSiblings).find(function (modelW) {
                return modelW.alfrescoId === model.alfrescoId;
            });
            var selectedSibling = _($scope.productCardDto.models).find(function (sibling) {
                return sibling.alfrescoId === model.alfrescoId;
            });

            selectedSibling.medias = sortModelMediasAndFixOrders(pristineModelWrapper);

            model.smartCopy = false;
            $scope.$broadcast('smartCopy');

            updateSmartCopyStatus();
        };

        function sortModelMediasAndFixOrders(modelMedias) {

            var packshotsSign = _.filter(modelMedias.modelPackshots, function (media) {
                return media.zone === 'SIGN_KIT';
            });
            var productMedias = _.filter(modelMedias.modelPackshots, function (media) {
                return media.zone === 'PRODUCT';
            });
            var contextMedias = _.filter(modelMedias.modelPackshots, function (media) {
                return media.zone === 'CONTEXT';
            });

            modelMedias.modelMedia360 = tampOrders(modelMedias.modelMedia360);
            modelMedias.modelMediaFilms = tampOrders(modelMedias.modelMediaFilms);
            modelMedias.modelPackshots = [].concat(tampOrders(packshotsSign), tampOrders(productMedias), tampOrders(contextMedias));

            return modelMedias;
        }


        function tampOrders(mediaArray) {
            var i = 1;
            var mediaArraySorted = _.sortBy(mediaArray, function (media) {
                return media.order;
            });
            return _.map(mediaArraySorted, function (media) {
                media.order = i++;
                return media;
            });
        }

        function updateSmartCopyStatus() {
            $scope.productCardSmartCopy = _($scope.siblings).some(function (sibling) {
                return sibling.smartCopy;
            });
        }

        var propagationModal = null;

        $scope.showChangeBrandPart = false;
        $scope.brands = [];
        brandService.getBrands().then(function (data) {
            $scope.allBrands = data;
        });
        $scope.refreshBrands = function () {
            $scope.brands = $scope.allBrands;
        };

        $scope.loadBrandsList = function () {
            if ($scope.productCardDto.brand != null) {
                $scope.brands.push($scope.productCardDto.brand);
                $scope.newBrand = {
                    id: $scope.productCardDto.brand.id
                };
            } else {
                $scope.newBrand = {
                    id: null
                };
            }
            $scope.showChangeBrandPart = true;
        };

        $scope.saveNewBrand = function () {
            brandService.saveBrand($scope.productCardDto.models[0].code, $scope.newBrand.id).then(function (data) {
                $scope.showChangeBrandPart = false;
                $scope.productCardDto.brand = data;
                $scope.productCardDto.brand.logoPath = data.logos[0].path;
                $window.location.reload();
            });
        };

        $scope.showChangeProductNaturePart = false;

        $scope.chargeNewProductNatureList = function () {
            $scope.newProductNature = {
                selection: $scope.productCardDto.productNature
            };

            let currentUserLocale = userSettings.getSelectedWorkingLocale();
            productNatureService.getProductNatures(currentUserLocale).then(function (data) {
                $scope.productNatures = data;
                $scope.showChangeProductNaturePart = true;
            });
        };

        $scope.saveNewProductNature = function () {
            const selectedProductNature = $scope.newProductNature.selection;
            const smCode = $scope.productCardDto.code;

            superModelService.editProductNature(smCode, selectedProductNature.id).then(function () {
                $analytics.eventTrack('Changement de Nature Produit', {
                    category: 'Fiche SM',
                    label: 'Changement de nature produit de ' + $scope.productCardDto.productNatureFR + ' vers ' + selectedProductNature.label
                });

                $scope.productCardDto.productNature = selectedProductNature;
                productNatureService.getProductNature(selectedProductNature.id, 'en_GB').then(function (productNature) {
                    $scope.productCardDto.productNatureEN = productNature;
                });
                productNatureService.getProductNature(selectedProductNature.id, 'fr_FR').then(function (productNature) {
                    $scope.productCardDto.productNatureFR = productNature;
                });
                $scope.productCardDto.productNature = selectedProductNature;
                toastService.displayTimedToast('Product Nature modified', 'success');
                $scope.showChangeProductNaturePart = false;
            });
        };

        $scope.openLocaleGlobal = function (value) {
            if (!addPictoModal) {
                addPictoModal = spidModal.open({
                    templateUrl: 'productCardNg/productIdentity/globalLocale/globalLocale.html',
                    controller: 'LocaleGlobalController',
                    size: 'md',
                    resolve: {
                        sourceSuperModel: function () {
                            return $scope.productCardDto;
                        },
                        value: function () {
                            return value;
                        }
                    }
                });
                addPictoModal.result.then(function (picture) {
                    $scope.productCardDto.brandLogoPath = picture;
                    $analytics.eventTrack('Changement de logo de marque', {
                        category: 'Fiche SM',
                        label: 'Changement de marque logo de marque'
                    });
                });

                addPictoModal.closed.then(function () {
                    addPictoModal = null;
                });
            }
        };

        $scope.specificLocal = function () {
            return isSpeLocal();
        };

        function isSpeLocal() {
            return !hasIntProduct;
        }

        $scope.checkOverride = function (value) {
            if ((!productCardDto.locale.contains('INT')) && !(isSpeLocal())) {
                if (productCardDto[value].override != null && productCardDto[value].override !== undefined) {
                    if ((productCardDto[value].override !== productCardDto[value].value)) {
                        return true;
                    }
                }
            } else {
                return false;
            }
        };

        $scope.openSuperModelEventsPopover = function () {
            var hasRole = $scope.isAdmin || userSettings.hasAuthority('ROLE_SUPPORT');

            $scope.superModelEventsPopupTitle = hasRole ? 'product.super_model_events' : 'product.super_model_events.season_only';
            $scope.superModelEventsIsOpen = !$scope.superModelEventsIsOpen;

            if ($scope.superModelEventsIsOpen) {
                superModelService.getSuperModelEvents($scope.productCardDto.code).then(function (response) {
                    var superModelEvents = response.data;
                    if (!hasRole) {
                        superModelEvents = _(superModelEvents).filter(function (superModelEvent) {
                            return superModelEvent.versionDate === $scope.model.versionDate;
                        });
                    }
                    $scope.superModelEvents = superModelEvents;

                });
            }
        };

        $scope.openSuperModelFamiliesPopover = function () {
            $scope.superModelFamiliesIsOpen = !$scope.superModelFamiliesIsOpen;

            if ($scope.superModelFamiliesIsOpen) {
                superModelService.getSuperModelFamilies($scope.productCardDto.smId, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (response) {
                    $scope.superModelFamilies = response.data;
                });
            }
        };
        $scope.eventOnClickOnTextArea = function () {
            if (!productCardDto.locale.contains('INT')) {
                toastService.displayToast($filter('translate')('product.textAreaAlert'), 'warning');
            }
        };

        $scope.displayLocalGlobalWarning = function () {
            if (!productCardDto.locale.includes('INT')) {
                toastService.displayTimedToast($filter('translate')('product.localGlobalAlert'), 'warning');
            }
        };
    }

    function productCardNgResolver() {
        return {
            rightToLeft: ['$stateParams', '$rootScope', 'countriesService', function ($stateParams, $rootScope, countriesService) {
                countriesService.isRightToLeftLocale($stateParams.initLocale || $stateParams.smLocale).then(function (rightToLeft) {
                    $rootScope.isRightToLeft = rightToLeft ? 'rtl' : 'ltr';
                });
            }],
            languages: ['productService', 'countriesService', 'userSettings', function (productService, countriesService, userSettings) {
                return countriesService.getCountries(userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (countries) {
                    var allLanguages = {};
                    countries.forEach(function (country) {
                        country.listLangages.forEach(function (language) {
                            allLanguages[language.code + '_' + country.code] = country.label + ' / ' + language.label + ' (' + language.code + '_' + country.code + ')';
                        });
                    });
                    return allLanguages;
                });
            }],
            previousSmSeason: [function () {
            }],
            timeline: ['$stateParams', 'TimelineService', function ($stateParams, timelineService) {
                return timelineService
                    .getSuperModelTimeline($stateParams.smCode)
                    .then(response => response);
            }],
            productCardDto: ['$stateParams', 'superModelService', 'mediaService', function ($stateParams, superModelService) {
                return superModelService.getSuperModelVersion($stateParams.smCode, $stateParams.versionDate, $stateParams.smLocale).then(function (response) {
                    return response.data;
                });
            }],
            materialsRepository: ['$stateParams', 'materialService', function ($stateParams, materialService) {
                return materialService.getMaterials($stateParams.initLocale || $stateParams.smLocale);
            }],
            genders: ['$stateParams', 'genderService', function ($stateParams, genderService) {
                return genderService.get($stateParams.initLocale || $stateParams.smLocale);
            }],
            userBenefits: ['$stateParams', 'userBenefitsService', function ($stateParams, userBenefitsService) {
                return userBenefitsService.getBUList($stateParams.initLocale || $stateParams.smLocale);
            }],
            sportsRepository: ['$stateParams', 'sportService', function ($stateParams, sportService) {
                return sportService.getSports($stateParams.initLocale || $stateParams.smLocale);
            }],
            structurations: ['$stateParams', 'structurationService', function ($stateParams, structurationService) {
                return structurationService.get($stateParams.initLocale || $stateParams.smLocale);
            }],
            colorsRepository: ['$stateParams', 'colorService', function ($stateParams, colorService) {
                return colorService.getColors($stateParams.initLocale || $stateParams.smLocale);
            }],
            flavorsRepository: ['$stateParams', 'flavorService', function ($stateParams, flavorService) {
                return flavorService.getFlavors($stateParams.initLocale || $stateParams.smLocale);
            }],
            fragrancesRepository: ['$stateParams', 'fragranceService', function ($stateParams, fragranceService) {
                return fragranceService.getFragrances($stateParams.initLocale || $stateParams.smLocale);
            }],
            productPartsRepository: ['$stateParams', 'productPartService', function ($stateParams, productPartService) {
                return productPartService.getProductParts($stateParams.initLocale || $stateParams.smLocale);
            }],
            careInstructionsRepository: ['$stateParams', 'careInstructionService', function ($stateParams, careInstructionService) {
                return careInstructionService.getCareInstructions($stateParams.initLocale || $stateParams.smLocale);
            }],
            careSymbolsRepository: ['$stateParams', 'careSymbolService', function ($stateParams, careSymbolService) {
                return careSymbolService.getCareSymbols($stateParams.initLocale || $stateParams.smLocale);
            }],
            warningsRepository: ['$stateParams', 'warningService', function ($stateParams, warningService) {
                return warningService.getWarnings($stateParams.initLocale || $stateParams.smLocale);
            }],
        };
    }

    let initInternationalConfirmationLanguagePopUp = ($stateParams, $rootScope, spidModal, $scope, isPublish) => {
        let languageRequired = '';

        if (typeof $stateParams.initLocale !== 'undefined') {
            if ($stateParams.initLocale.substring(0, 2) === 'fr') {
                languageRequired = 'fr_FR';
            } else {
                languageRequired = 'en_GB';
            }
        }

        if ($stateParams.smLocale === 'int_INT' && $rootScope.workingLocale.locale !== languageRequired) {
            $scope.initConfirmationPopup = true;
            $scope.isPublish = isPublish;

            const initModal = spidModal
                .open({
                    templateUrl: 'productCardNg/popup/initConfirmationPopup.html',
                    scope: $scope
                });
            initModal.result.then(() => {
                $scope.initConfirmationPopup = false;
            });
        }
    };
})();
