(function () {
    'use strict';

    angular.module('spidwebApp').factory('modelService', modelService);

    modelService.$inject = [
        'axiosService',
        'envService',
        'productNatureService',
    ];

    function modelService(axiosService, envService, productNatureService) {
        return {
            countMedias: function (modelSeasonId, locale) {
                return axiosService
                    .get(
                        envService.getModelSeasonsContext() +
                            '/' +
                            modelSeasonId +
                            '/medias/count?' +
                            Object.entries({ locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getModelSeasonsLocalized: function (modelCode) {
                return axiosService
                    .get(
                        envService.getModelSeasonsContext(),
                        {
                            params: {
                                codeModele: modelCode,
                                internationalLocales: true,
                                published: true,
                            },
                            data: {},
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getModelSeasonsLocalizedByModelCodeOrCodeConceptionOrSmCode:
                function (modelCode, smCode, codeConception, sourceSuperModel) {
                    return axiosService
                        .get(
                            envService.getSuperModelsContext() +
                            '/previousSeasons?modelCode=' + modelCode + '&smCodeSource=' + sourceSuperModel.code +
                            '&smCode=' + smCode + '&codeConception=' + codeConception + '&versionDate=' +
                            sourceSuperModel.versionDate,
                            {
                                data: {},
                                withCredentials: true,
                                headers: {
                                    Accept: 'application/json;charset=UTF-8',
                                },
                            }
                        )
                        .then(function (response) {
                            return response.data;
                        });
                },
            getModelData: function (modelCode, locale, smSeasonId) {
                return axiosService.get(
                    envService.getProductCardContext() +
                        '/' +
                        modelCode +
                        '/modelDto',
                    {
                        params: {
                            locale,
                            smSeasonId,
                        },
                        data: {},
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );
            },
            getSphereContentUrl: function (modelCode, locale) {
                return axiosService
                    .get(
                        envService.getModelsContext() +
                            '/' +
                            modelCode +
                            '/sphere',
                        {
                            params: { locale },
                            data: {},
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (response) {
                        return response.data;
                    });
            },
            getAvailableSignKit: function (modelCode, locale) {
                return axiosService
                    .get(
                        envService.getModelsContext() +
                            '/' +
                            modelCode +
                            '/formats',
                        {
                            params: { locale },
                            data: {},
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getDefaultFormat: function (modelCode) {
                return axiosService
                    .get(
                        envService.getModelsContext() +
                            '/' +
                            modelCode +
                            '/defaultFormat',
                        {
                            data: {},
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (response) {
                        return response.data;
                    });
            },
            getVersionsBySmCode: function (modelCode, locale) {
                return axiosService
                    .get(
                        envService.getAllSeasonByCodeModelUrl() +
                            '/' +
                            modelCode +
                            '/versions',
                        {
                            params: { locale },
                            data: {},
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getArticles: function (modelCode) {
                return axiosService
                    .get(envService.getArticlesByModelUrl(modelCode), {
                        data: {},
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    })
                    .then(function (result) {
                        var articleMasterDTOs = result.data ? result.data : [];
                        if (_(articleMasterDTOs).isEmpty()) {
                            return [];
                        }

                        return articleMasterDTOs.map(function (article) {
                            return {
                                articleId: article.article_id,
                                articleSizeLabel: article.grid_value_id,
                            };
                        });
                    });
            },
            cleanDimensions: function (productNature, model) {
                var dimensionType =
                    productNatureService.getProductNatureType(productNature);
                if (dimensionType == 'CLOTHING') {
                    _(model.items).each(function (article) {
                        article.weightUnit = 'kg';

                        article.capacityValue = null;
                        article.capacityUnit = null;
                    });
                    model.capacityValue = null;
                    model.capacityUnit = null;
                } else {
                    _(model.items).each(function (article) {
                        article.weightUnit = 'kg';

                        if (!article.capacityValue) {
                            article.capacityUnit = null;
                        } else {
                            article.capacityUnit = 'L';
                        }
                    });
                    if (!model.capacityValue) {
                        model.capacityUnit = null;
                    }
                }
            },
        };
    }
})();
